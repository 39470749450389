:root {
  --wheel-font: "Lato", "Quicksand", sans-serif;
  --wheel-size: 140px;
  --wheel-slice-spacing: 5px;
  --wheel-border-size: 0px;
  --wheel-color: #101010;
  --neutral-color: white;
  --PI: 3.14159265358979;
  --nb-item: 0;
  --item-nb: 0;
  --selected-item: 0;
  --nb-turn: 5;
  --spinning-duration: 4s;
  --reset-duration: 0.25s;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  user-select: none;
}

body {
  overflow: hidden;
}

.background-image {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  display: block;
  background-image: url("../../..//public/img/luck77-bg.png") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(0px);
}

/* Conteneur de la roue = cercle rose extérieur */
.wheel-container {
  display: block;
  position: relative;
  box-sizing: content-box;
  width: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
  height: calc(var(--wheel-size) + 2 * var(--wheel-border-size));
  padding: 10px;
  margin: auto;
  background-color: var(--neutral-color);
  // border: solid var(--wheel-color) 3px;
  background: url("../../../public/img/wheel-bg.png");

  background-position: center;
  background-size: cover;
  border-radius: 50%;
  user-select: none;
  // transform: rotate(90deg);
  // margin-top: 150px;
}

/* Triangle de sélection = 1 rose +  1 blanc */
.wheel-container::before {
  content: "";
  display: block;
  position: absolute;
  height: 28px;
  width: 25px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  // border: solid transparent 20px;
  // border-left-width: 0;

  background: url("../../../public/img/arrow.png");
  background-size: cover;
}

// .wheel-container::before {
//   right: 0px;
//   border-right-color: var(--wheel-color);
// }

// .wheel-container::after {
//   right: -5px;
//   border-right-color: var(--neutral-color);
// }

/* Roue */
.wheel {
  display: block;
  position: relative;
  box-sizing: content-box;
  margin: auto;
  width: var(--wheel-size);
  height: var(--wheel-size);
  overflow: hidden;
  border-radius: 50%;
  border: solid var(--wheel-color) var(--wheel-border-size);
  // background-color: var(--wheel-color);
  background: url("../../../public/img/main-wheel.png");
  box-shadow: inset 0px 0px 5px 3px black;

  background-size: cover;
  background-position: center;

  transition: transform var(--reset-duration);
  transform: rotate(0deg);
}

.spinning {
  transition: transform var(--spinning-duration);
  transform: rotate(
    calc(
      var(--nb-turn) * 360deg +
        (-360deg * var(--selected-item) / var(--nb-item, 1))
    )
  );
}

/* Centre de la roue = rond blanc au centre */
.wheel::after {
  display: block;
  position: absolute;
  content: "";
  transform: rotate(90deg);
  // background-color: var(--neutral-color);
  width: 25px;
  height: 25px;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

/* Element sur la roue */
.wheel-item {
  display: block;
  position: absolute;
  box-sizing: border-box;

  /* position de l'item */
  top: 50%;
  left: 50%;
  width: 50%;
  transform-origin: center left;
  transform: translateY(-50%)
    rotate(calc(var(--item-nb) * (360deg / var(--nb-item, 1))));

  /* texte */
  color: var(--neutral-color);
  text-align: right;
  padding: 0 25px 0 50px;
  font-family: var(--wheel-font);
}

/* Background de l'élément = triangle rose plus clair */
.wheel-item:before {
  content: " ";
  display: block;
  position: absolute;
  box-sizing: border-box;
  z-index: -1;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 0px;
  opacity: 0.25;

  /* 
      Largeur du triangle
      Je ne sais pas pourquoi j'ai besoin d'ajouter 
        " + var(--wheel-size) / 2 " dans slice-max-width  ==> donne 2.PI.R + R
      mais globalement ca marche a peu près bien 
      TODO: A étudier 
    */
  --slice-max-width: calc(
    var(--PI) * var(--wheel-size) + var(--wheel-size) / 2
  );
  --slice-width: calc(
    (var(--slice-max-width) / var(--nb-item)) - var(--wheel-slice-spacing)
  );
  border: solid transparent calc(var(--slice-width) / 2);
  border-left: solid transparent 0;
  /* profondeur du triangle = du centre de la roue à la bordure = 300px / 2 */
  // border-right: solid var(--neutral-color) calc(var(--wheel-size) / 2);
}

// new css
.bottom-0 {
  bottom: 0 !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  padding: 8px 8px !important;
}

.bet-cards {
  display: flex;
  justify-content: space-evenly;

  position: absolute;
  bottom: 50px;

  left: 0;
  right: 0;

  .pot_a {
    background-image: url("../../../public/img/pot_c.png");
  }

  .pot_b {
    background-image: url("../../../public/img/pot_b.png");
  }

  .pot_c {
    background-image: url("../../../public/img/pot_a.png");

  }

  .mcard {
    // border: 1px solid rgba($color: #fff, $alpha: 0.5);
    position: relative;
    width: 80px;
    height: 115px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    transition: all 0.5s;

    .footer {
      color: gold;

      position: absolute;
      top: 8px;
      left: 8px;
      font-size: 14px;
    }

    .body {
      h3 {
        padding: 0;
        font-size: 28px;
        margin: 0;
        color: gold;
        font-weight: 900;
        text-align: center;
      }

      padding-bottom: 25px;
    }

    img {
      width: 100px;
      padding: 0 20px;
    }

    &:active {
      transition: unset;
      box-shadow: 0px 0px 10px gold;
    }
  }
}

.clock-luck77 {
  // color: #5400a7;
  top: 82px;
}

.win-animation {
  animation: shadow 1s ease 2;
  z-index: 10000;
  box-shadow: 0px 0px 10px gold;
}

@keyframes shadow {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}

.close-btn-77 {
  width: 22px;
  height: 22px;
  background: url("../../../public/img/77-close-btn.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 12px;
  right: 12px;
}
