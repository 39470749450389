.modal-overlap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: #000000, $alpha: 0.5);
  z-index: 10000000;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    position: relative;
    background-image: url("../../..//public/img/luck77-bg.png") !important;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 12px;
    padding: 8px;
    width: 300px;
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.5);

    .close-btn-77 {
      top: -8px;
      right: -8px;
      box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.5);

      border-radius: 50%;
    }

    .header {
      text-align: center;
      padding: 8px 0;
    }

    .footer {
      display: flex;
      justify-content: space-evenly;
      padding: 8px 0;

      border-top: 1px solid silver;
      .item {
        display: flex;
        align-items: center;
        img {
          width: 25px;

          margin-right: 4px;
        }
      }
    }

    .icons {
      display: flex;
      flex-wrap: wrap;

      .icon,
      .img {
        width: 20px;
        position: relative;
      }

      .icon {
        margin: 4px;
      }

      .icon:first-child:after {
        content: "New";
        position: absolute;
        top: -10px;
        left: -10px;
        font-size: 10px;
        z-index: 100;
        color: red;
        text-shadow: 1px 1px 3px black;
        transform: rotate(-30deg);
      }
    }
  }
}
